



// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    gui_options: {
        consent_modal : {
            layout: 'box',
            position: 'middle center',
            transition: 'zoom',
        },
        settings_modal : {
            layout: 'box',
            position: 'middle center',
            transition: 'zoom',
        }
    },
    current_lang: 'pl',
    force_consent: true,                       // default: false
    cookie_expiration: 365,                    // default: 182 (days)
    revision: 0,                               // default: 0
    // autoclear_cookies: true,                   // default: false
    // theme_css: '<path-to-cookieconsent.css>',  // 🚨 replace with a valid path
    // page_scripts: true,                        // default: false

    mode: 'opt-in',                            // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    autorun: true,                          // default: true
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
    },

    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://partnerpszczelarza.pl/polityka-prywatnosci/" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            }
                        ]
                    }, {
                        title: 'Advertisement and Targeting cookies',
                        description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'More information',
                        description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                    }
                ]
            }
        },
        'pl': {
            consent_modal: {
                title: 'Używamy plików cookies!',
                description: '<strong>Zanim klikniesz przycisk i dokonasz wyboru, prosimy o przeczytanie tej informacji do końca. Dotyczy ona Twoich danych osobowych i przysługujących Tobie praw.</strong><br/><br/>' +
                    '<strong>Klikając „Przejdź do aplikacji” udzielasz zgody na przetwarzanie Twoich danych osobowych</strong> dotyczących Twojej aktywności w naszej aplikacji, w Internecie (np. identyfikatory urządzenia, adres IP) przez nas – czyli Partner Pszczelarza Sp. z o. o. oraz naszych Zaufanych Partnerów w celach marketingowych (w tym dokonywania pomiarów), w celu dostosowania dostarczanych treści oraz w pozostałych celach podanych poniżej. Szczegółowy opis celów i zakresu przetwarzanych danych znajdziesz tutaj - obejmują one (klasyfikacja wg IAB Europe) m.in.: tworzenia profilu spersonalizowanych treści; wybór spersonalizowanych treści; pomiar wydajności treści; stosowanie badań rynkowych w celu generowania opinii odbiorców; opracowywanie i ulepszanie produktów oraz funkcje specjalne takie jak: użycie dokładnych danych geolokalizacyjnych i aktywne skanowanie charakterystyki urządzenia do celów identyfikacji.<br/><br/>' +
                    '<strong>Zgoda jest dobrowolna. Możesz jej udzielić bez zastrzeżeń (w najszerszym dozwolonym zakresie), możesz w całości odmówić jej udzielenia albo ograniczyć jej zakres klikając w „Ustawienia zaawansowane”.</strong><br/><br/>' +
                    'My i Zaufani Partnerzy przetwarzamy Twoje dane podane powyżej oraz dane z Twoich kont w naszych aplikacjach w niektórych z ww. celów w oparciu o prawnie uzasadniony interes. Przetwarzanie to nie wymaga zgody z Twojej strony, ale możesz mu się w każdej chwili sprzeciwić.<br/>' +
                    'W niektórych naszych usługach (np. newsletter) – w przypadkach wyraźnie przez nas wskazanych, dla przetwarzania Twoich danych osobowych konieczna jest Twoja wyraźna zgoda. Prosimy Cię o jej udzielenie poprzez zaznaczenie właściwego pola, ale to Twoja decyzja.<br/><br/>' +
                    'Twoje prawa:<br/>' +
                    'Udzieloną zgodę możesz wycofać. Możesz żądać dostępu do Twoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, przeniesienia danych, wyrazić sprzeciw wobec ich przetwarzania i wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych (UODO).<br/>' +
                    'Szczegółowe informacje znajdziesz w „Ustawieniach zaawansowanych”.<br/><br/>' +
                    'Korzystanie z aplikacji bez zmiany ustawień przeglądarki internetowej lub ustawień Twojego urządzenia mobilnego oznacza też zgodę na umieszczanie znaczników internetowych (np. pliki cookies / ciasteczka) na Twoich urządzeniach i odczytywanie ich (przechowywanie informacji na urządzeniu lub dostęp do nich) przez Partner Pszczelarza oraz przez Zaufanych Partnerów. Zgody tej możesz odmówić lub ją ograniczyć poprzez zmianę ustawień przeglądarki lub swojego urządzenia mobilnego (<a href="https://partnerpszczelarza.pl/polityka-prywatnosci#przegladarki" class="cc-link" target="_blank">szczegółowe informacje znajdziesz tutaj</a>).',
                primary_btn: {
                    text: 'Akceptuję wszystkie i przechodzę do serwisu',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Ustawienia zaawansowane',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Ustawienia zaawensowane',
                save_settings_btn: 'Zapisuję i przechodzę do serwisu',
                accept_all_btn: 'Ackceptuję wszystkie',
                reject_all_btn: 'Akceptuję niezbędne',
                close_btn_label: 'Zamknij',
                cookie_table_headers: [
                    {col1: 'Nazwa'},
                    {col2: 'Dostawca'},
                    {col3: 'Data ważości'},
                    {col4: 'Cel i rodzaj'}
                ],
                blocks: [
                    {
                        title: 'Tu możesz zarządzać procesami przetwarzania Twoich danych osobowych w aplikacjach dostarczanych przez Partner Pszczelarza sp z o. o.',
                        description: '<strong>Zgoda i inne podstawy przetwarzania danych</strong><br/>' +
                            'Jeśli chcesz zgodzić się na przetwarzanie przez naszych Zaufanych Partnerów Twoich danych osobowych, zawierających informacje o aplikacjach internetowych, z których korzystasz, informacje zapisane w plikach cookies w Twoim urządzeniu i jego pamięci podręcznej oraz dane lokalizacyjne generowane przez Twoje urządzenie, w celach marketingowych (obejmujących zautomatyzowaną analizę Twojej aktywności w naszych aplikacjach) w tym na umieszczanie znaczników internetowych (cookies) na Twoich urządzeniach i odczytywanie takich znaczników, kliknij przycisk <string>„Przechodzę do Serwisu”</string> lub zamknij to okno.<br/>' +
                            'Jeśli nie chcesz wyrazić zgody lub chcesz ograniczyć jej zakres, kliknij „Ustawienia zaawansowane”. Wyrażenie zgody jest dobrowolne. Możesz edytować zakres zgody (w tym np. udzielić jej dla danej grupy administratorów lub dla danego rodzaju działań), w tym wycofać ją całkowicie, klikając „Ustawienia zaawansowane” lub przechodząc do „Polityki prywatności”.<br/>' +
                            '<br/>' +
                            'Powyższa zgoda dotyczy przetwarzania Twoich danych osobowych w celach marketingowych Zaufanych Partnerów. Zaufani Partnerzy to dostawcy usług hostingowych, usług e-commerce i reklamodawcy oraz związki pszczelarskie w Polsce.<br/>' +
                            'Niektórzy z Zaufanych Partnerów mogą przetwarzać Twoje dane w węższym zakresie, niektórzy z nich mogą też przetwarzać te dane w oparciu o inną podstawę niż Twoja zgoda (np. w oparciu o uzasadniony interes administratora). Kompletną listę Zaufanych Partnerów wraz z zakresem danych przetwarzanych przez nich w oparciu o Twoją zgodę lub inną podstawę prawną znajdziesz <a href="https://partnerpszczelarza.pl/polityka-prywatnosci#partnerzy" class="cc-link" target="_blank">bezpośrednio pod tym linkiem.</a><br/>' +
                            '<br/>' +
                            'W ramach oferowanych przez nas usług przetwarzamy Twoje dane opisane na wstępie, a także zagregowane informacje o wieku, płci, statusie w organizacja pszczelarskich, profilu działalności pszczelarskiej i lokalizacji (wielkości miasta z którego pochodzisz itp.) pochodzące z Twoich kont w naszych aplikacjach - w celach marketingowych w oparciu o prawnie uzasadniony interes. Dane te są jednocześnie danymi niezbędnymi do realizacji umowy zawartej z Tobą, która dotyczy korzystania przez Ciebie z naszych usług i aplikacji, ze względu na właściwości tych usług. Treść tej umowy określa regulamin aplikacji, nasza Polityka Prywatności, lub regulaminy innych świadczonych przez nas usług.<br/>' +
                            '<br/>' +
                            'Twoja dobrowolna zgoda będzie też podstawą transferu Twoich danych przez część z naszych Zaufanych Partnerów do państw trzecich tj. poza EOG. Poza EOG, np. w USA, stopień ochrony danych osobowych może być słabszy niż wymagany w UE. Informacje o Zaufanych Partnerach spoza EOG znajdują się w politykach prywatności Zaufanych Partnerów.<br/>' +
                            'Kiedy przekazujemy Twoje dane osobowe samodzielnie lub za pośrednictwem usługodawców w państwach spoza Unii Europejskiej, przestrzegamy przepisów art. 44 i nast. RODO, a także zobowiązujemy naszych usługodawców do przestrzegania tych przepisów. Dlatego będziemy przekazywać Twoje dane do podmiotów z państw spoza Unii Europejskiej tylko wtedy, gdy będzie przestrzegany poziom ochrony zapewniany przez RODO. Ten poziom ochrony jest zapewniony w szczególności poprzez decyzję Komisji Europejskiej stwierdzającą odpowiedni stopień ochrony lub poprzez odpowiednie gwarancje zgodnie z art. 46 RODO.<br/>' +
                            '<br/>' +
                            'Prawnie uzasadniony interes administratora to jedna z podstaw do przetwarzania danych osobowych (obok m.in. zgody czy konieczności realizacji umowy) i obejmuje on przypadki, w których administrator danych z uwagi na zgodne z prawem cele może przetwarzać dane osobowe nie godząc w prawa, wolności, interesy podmiotów danych. Przykładem takiej sytuacji opisanym w obowiązujących przepisach jest m.in. przetwarzanie danych osobowych do celów marketingu bezpośredniego.<br/>' +
                            'Prawnie uzasadniony interes administratora będzie stanowił podstawę prawną tylko dla takiego przetwarzania danych osobowych, które jest niezbędne dla realizacji celu wynikającego z tego interesu. Istnienie prawnie uzasadnionego interesu oceniane jest w określonym czasie i kontekście, w którym dane są zbierane. Badane jest czy osoba, której dane dotyczą może spodziewać się, że administrator przetwarza jej dane w określonym celu oraz czy jej interesy, prawa podstawowe i wolności pozostają z tym interesem w równowadze.<br/>' +
                            '<br/>' +
                            'Przetwarzanie takie nie wymaga dodatkowej zgody z Twojej strony, ale możesz mu się w każdej chwili sprzeciwić. O ile nie zdecydujesz inaczej, dokonując stosownych zmian ustawień w Twojej przeglądarce lub urządzeniu, będziemy instalować na Twoich urządzeniach pliki cookies i podobne oraz odczytywać informacje z takich plików. Bliższe informacje o cookies znajdziesz w sekcji „Cookies” poniżej.<br/>' +
                            '<br/>' +
                            '<strong>Administrator danych osobowych</strong><br/>' +
                            'Administratorem Twoich danych osobowych jest Partner Pszczelarza sp z o. o., z którego aplikacji korzystasz. Naszą nazwę znajdziesz zawsze na dole ekranu aplikacji, a nasze logo – w menu głównym i na ekranie głównym aplikacji. Informację o powołaniu przez nas inspektora ochrony danych osobowych i jego danych kontaktowych znajdziesz też w Polityce Prywatności. Jeżeli wyrazisz zgodę, o którą wyżej prosimy, administratorami Twoich danych osobowych będą także nasi Zaufani Partnerzy. Listę Zaufanych Partnerów możesz sprawdzić tutaj i, a udzieloną zgodę możesz zmodyfikować lub cofnąć w ustawieniach swojego konta lub przechodząc do Polityki Prywatności. Pamiętaj, że cofnięcie zgód uniemożliwi realizację działań, których te zgody dotyczą, a więc np. zamiast otrzymywać treści, które odpowiadają Twoim zainteresowaniom możesz zacząć otrzymywać treści, które nie będą dla Ciebie interesujące.<br/>' +
                            '<br/>' +
                            '<strong>Podstawa i cel przetwarzania</strong><br/>' +
                            'Jeżeli uzyskamy zgodę na przetwarzanie Twoich danych osobowych, podstawą prawną do przetwarzania danych jest art. 6 ust. 1 lit. a RODO.<br/>' +
                            'Jeżeli przetwarzamy Twoje dane osobowe, ponieważ jest to niezbędne do wykonania umowy lub w ramach stosunku umownego z Tobą, podstawą prawną do przetwarzania danych jest art. 6 ust. 1 lit. b RODO.<br/>' +
                            'Jeżeli przetwarzamy Twoje dane osobowe w celu wypełnienia obowiązku prawnego, podstawą prawną do przetwarzania danych jest art. 6 ust. 1 lit. c RODO.<br/>' +
                            'Art. 6 ust. 1 lit. f RODO może być również wykorzystany jako podstawa prawna przetwarzania danych, jeżeli przetwarzanie Twoich danych osobowych jest niezbędne do celów wynikających z naszych prawnie uzasadnionych interesów lub osoby trzeciej, a jednocześnie nadrzędnego charakteru wobec tych interesów nie mają Twoje interesy, podstawowe prawa i wolności wymagające ochrony danych osobowych.<br/>' +
                            'W ramach niniejszej polityki prywatności zawsze wskazujemy podstawę prawną uzasadniającą przetwarzanie Twoich danych osobowych.<br/><br/>' +
                            '<strong>Twoje dane przetwarzamy w następujących celach:</strong><br/><ol>' +
                            '<li>Gdy zawieramy z Tobą umowę o korzystanie z naszej aplikacji (dzieje się tak, gdy zakładasz konto w naszej aplikacji) lub gdy wyrażasz zgodę na otrzymywanie powiadomień albo newslettera, to możemy przetwarzać Twoje dane w zakresie niezbędnym do realizacji tej umowy. Bez tego nie bylibyśmy w stanie zapewnić Ci usługi, a Ty nie mógłbyś z niej korzystać. Niepodanie takich danych uniemożliwi nam zawarcie i wykonanie umowy.</li>' +
                            '<li>Zapewnianie bezpieczeństwa usługi (np. sprawdzenie, czy do Twojego konta nie loguje się nieuprawniona osoba), dokonanie pomiarów statystycznych, ulepszanie naszych usług i dopasowanie ich do potrzeb i wygody użytkowników (np. personalizowanie treści w usługach), jak również prowadzenie marketingu bezpośredniego lub promocji własnych usług, to możemy Ci wyświetlić dopasowane do Ciebie treści. Takie przetwarzanie danych to realizacja naszych prawnie uzasadnionych interesów, w takim wypadku zawsze przeprowadzamy też test równowagi pomiędzy naszym uzasadnionym prawnie interesem, a prawami osób, których dane dotyczą.</li>' +
                            '<li>Za Twoją zgodą usługi marketingowe dostarczą Ci nasi Zaufani Partnerzy oraz my dla podmiotów trzecich. Aby móc pokazać interesujące Cię informacje lub reklamy (gdy takie będą już wyświetlane w naszej aplikacji) reklamodawcy i ich przedstawiciele chcieliby mieć możliwość przetwarzania Twoich danych związanych z odwiedzanymi przez Ciebie stronami internetowymi. Udzielenie takiej zgody jest dobrowolne, nie musisz jej udzielać, nie pozbawi Cię to dostępu do naszych usług. Masz również możliwość ograniczenia zakresu lub zmiany zgody w dowolnym momencie.</li></ol>' +
                            'Twoje dane przetwarzane będą do czasu istnienia podstawy do ich przetwarzania, czyli w przypadku udzielenia zgody do momentu jej cofnięcia, ograniczenia lub innych działań z Twojej strony ograniczających tę zgodę, w przypadku niezbędności danych do wykonania umowy, przez czas jej wykonywania i ewentualnie okres przedawnienia roszczeń z niej (maksymalnie 6 lat), a w przypadku, gdy podstawą przetwarzania danych jest uzasadniony interes administratora, do czasu zgłoszenia przez Ciebie skutecznego sprzeciwu.<br/>' +
                            '<br/>' +
                            '<strong>Przekazywanie danych</strong><br/>' +
                            'Administratorzy danych mogą powierzać Twoje dane podwykonawcom usług IT, dostawcom usług e-commerce, usług hostingowych, księgowym, doradcom prawnym, agencjom marketingowym, organizacjom pszczelarskim, do których należysz – wyłącznie w zakresie danych niezbędnych tym podmiotom do realizacji zawartych z nami umów. Zrobią to jedynie w niezbędnym zakresie i na podstawie umowy o powierzenie przetwarzania danych zobowiązującej taki podmiot do odpowiedniego zabezpieczenia danych i niekorzystania z nich do własnych celów.<br/>' +
                            '<br/>' +
                            '<strong>Cookies</strong><br/>' +
                            'Jeśli nie jesteś pewien czym są pliki cookies <a href="https://partnerpszczelarza.pl/polityka-prywatnosci/#cookies" class="cc-link" target="_blank">sprawdź tutaj</a>.<br/>' +
                            '<br/>' +
                            'Na naszych stronach i w aplikacjach używamy znaczników internetowych takich jak pliki np. cookie lub local storage do zbierania i przetwarzania danych osobowych w celu personalizowania treści oraz analizowania ruchu w aplikacjach i w Internecie. W ten sposób technologię tę wykorzystują również nasi Zaufani Partnerzy. Cookies to dane informatyczne zapisywane w plikach i przechowywane na Twoim urządzeniu końcowym (tj. twój komputer, tablet, smartphone itp.), które przeglądarka wysyła do serwera przy każdorazowym wejściu na stronę z tego urządzenia, podczas gdy odwiedzasz strony w Internecie, korzystasz z naszej aplikacji. Szczegółową informację na temat plików cookie i ich funkcjonowania znajdziesz <a href="https://partnerpszczelarza.pl/polityka-prywatnosci/#cookies" class="cc-link" target="_blank">pod tym linkiem</a>. Pod tym linkiem znajdziesz także informację o tym jak zmienić ustawienia przeglądarki, aby ograniczyć lub wyłączyć funkcjonowanie plików cookies itp. oraz jak usunąć takie pliki z Twojego urządzenia.<br/>' +
                            'Twoje uprawnienia:<br/>' +
                            'Przysługują Ci następujące uprawnienia wobec Twoich danych i ich przetwarzania przez nas i Zaufanych Partnerów:<br/>' +
                            '<br/>' +
                            '1. Jeśli udzieliłeś zgody na przetwarzanie danych możesz ją w każdej chwili wycofać (cofnięcie zgody oczywiście nie uchyli zgodności z prawem przetwarzania już dokonanego na jej podstawie);<br/>' +
                            '2. Masz również prawo żądania dostępu do Twoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przeniesienia danych, wyrażenia sprzeciwu wobec przetwarzania danych oraz prawo do wniesienia skargi do organu nadzorczego, którym w Polsce jest Prezes Urzędu Ochrony Danych Osobowych. W <a href="https://partnerpszczelarza.pl/polityka-prywatnosci" class="cc-link" target="_blank">Polityce Prywatności</a> znajdziesz dodatkowe informacje dotyczące przetwarzania danych i Twoich uprawnień.<br/>' +
                            '<br/>' +
                            'W zakładce widocznej poniżej uzyskasz niezbędne informacje o celach, zakresach i podstawach przetwarzania Twoich danych osobowych, administratorach tych danych oraz będziesz mogła/mógł zarządzić tym przetwarzaniem.<br/>' +
                            'Możesz w niej określić którym z Zaufanych Partnerów chcesz wyrazić zgodę na przetwarzanie Twoich danych osobowych, a także ustalić cele przetwarzania, których dotyczyć ma Twoja zgoda – służy do tego suwak oznaczony jako „zgoda”.<br/>' +
                            'Pozostawienie suwaka odznaczonego (ma on wtedy kolor szary) oznacza brak zgody, zaś kliknięcie w suwak powodować będzie wyrażenie zgody (suwak zmieni kolor i przestanie być szary), której on dotyczy – jej zakres jest podany przy danym suwaku. Pamiętaj, by potwierdzić Twój wybór klikając po dokonaniu wybranych przez Ciebie ustawień w przycisk <strong>„Zapisuję i przechodzę do serwisu”</strong>.<br/>' +
                            'Niektórzy z Zaufanych Partnerów przetwarzać mogą Twoje dane w oparciu o ich uzasadniony interes. W takim wypadku nie udzielasz im zgody na przetwarzanie Twoich danych, ale możesz wyrazić sprzeciw wobec takiego przetwarzania. W tym celu skorzystaj z suwaka oznaczonego jako „UIA”.<br/>' +
                            '<br/>' +
                            'Polityka prywatności określa szczegółowy opis celów przetwarzania danych dla których możesz udzielić zgód (ograniczyć je albo odwołać), a poniżej prezentujemy ich możliwe rodzaje z opisem stworzonym przez IAB Europe:<br/>' +
                            '<strong>Cele:</strong><br/><ul>' +
                            '<li>Przechowywanie informacji na urządzeniu lub dostęp do nich <br/>' +
                            '(Dostawcy mogą: <br/>' +
                            '- przechowywać na urządzeniu informacje, takie jak pliki cookie i identyfikatory urządzenia przedstawiane użytkownikowi, oraz uzyskiwać do nich dostęp)</li>' +
                            '<li>Wybór podstawowych reklam <br/>' +
                            '(Aby dokonać podstawowego wyboru reklam, dostawcy mogą: <br/>' +
                            '- używać informacji w czasie rzeczywistym na temat kontekstu, w jakim zostanie wyświetlona reklama, aby wyświetlić reklamę, w tym informacji o treści i urządzeniu, takich jak: typ urządzenia i jego parametry, aplikacja klienta, adres URL, adres IP; <br/>' +
                            '- używać nieprecyzyjnych (przybliżona lokalizacja obejmująca co najmniej promień 500 metrów) danych geolokalizacyjnych użytkownika; <br/>' +
                            '- kontrolować częstotliwość reklam wyświetlanych użytkownikowi; <br/>' +
                            '- ustawić kolejność wyświetlania reklam użytkownikowi; <br/>' +
                            '- zapobiec pojawieniu się reklamy w nieodpowiednim kontekście redakcyjnym (niebezpiecznym dla marki). <br/>' +
                            'Dostawcy nie mogą: <br/>' +
                            '- tworzyć profilu spersonalizowanych reklam przy użyciu tych informacji do wyboru przyszłych reklam bez osobnej podstawy prawnej do utworzenia profilu spersonalizowanych reklam.)</li>' +
                            '<li>Tworzenie profilu spersonalizowanych reklam <br/>' +
                            '(Aby stworzyć profil spersonalizowanych reklam, dostawcy mogą: <br/>' +
                            '- zbierać informacje o użytkowniku, w tym o działaniach użytkownika, jego zainteresowaniach, wizytach na stronach lub w aplikacjach, dane demograficzne lub lokalizacyjne w celu stworzenia lub edytowania profilu użytkownika w celu personalizacji reklam; <br/>' +
                            '- łączyć te informacje z innymi wcześniej zebranymi informacjami, w tym z różnych witryn i aplikacji, w celu stworzenia lub edytowania profilu użytkownika w celu personalizacji reklam.)</li>' +
                            '<li>Wybór spersonalizowanych reklam <br/>' +
                            '(Aby wybrać spersonalizowane reklamy, dostawcy mogą: <br/>' +
                            '- wybrać spersonalizowane reklamy w oparciu o profil użytkownika lub inne historyczne dane użytkownika, w tym wcześniejsze działania użytkownika, zainteresowania, wizyty na stronach lub w aplikacjach, lokalizację lub dane demograficzne.</li>' +
                            '<li>Tworzenie profilu spersonalizowanych treści <br/>' +
                            '(Aby stworzyć profil spersonalizowanych treści, dostawcy mogą: <br/>' +
                            '- zbierać informacje o użytkowniku, w tym działaniach użytkownika, jego zainteresowaniach, wizytach na stronach lub w aplikacjach, dane demograficzne lub lokalizacyjne w celu stworzenia lub edytowania profilu użytkownika w celu personalizacji treści.)</li>' +
                            '<li>Wybór spersonalizowanych treści <br/>' +
                            '(Aby wybrać spersonalizowane treści, dostawcy mogą: <br/>' +
                            '- wybrać spersonalizowane treści w oparciu o profil użytkownika lub inne historyczne dane użytkownika, w tym wcześniejsze działania użytkownika, zainteresowania, wizyty na stronach lub w aplikacjach, lokalizację lub dane demograficzne.)</li>' +
                            '<li>Pomiar wydajności reklam <br/>' +
                            '(Aby dokonać pomiaru wydajności reklam, dostawcy reklam mogą: <br/>' +
                            '- zmierzyć, czy ii w jaki sposób reklamy były dostarczane użytkownikowi i jaka była jego reakcja; <br/>' +
                            '- dostarczać raporty dotyczące reklam, w tym ich skuteczności i wydajności; <br/>' +
                            '- dostarczać raporty dotyczące użytkowników, którzy zareagowali na reklamy, z użyciem danych obserwowanych podczas reakcji użytkownika na tę reklamę; <br/>' +
                            '- dostarczać raporty wydawcom na temat reklam wyświetlanych na ich powierzchni; <br/>' +
                            '- zmierzyć, czy reklama jest obsługiwana w odpowiednim środowisku redakcyjnym (bezpieczeństwo marki); <br/>' +
                            '- określić, w jakiej wartości procentowej reklama mogła zostać obejrzana, a także czas trwania tej możliwości obejrzenia. <br/>' +
                            'Dostawcy nie mogą: <br/>' +
                            '- stosować danych pochodzących z analizy panelu lub podobnych odbiorców do danych pomiarowych z reklam bez odrębnej podstawy prawnej do zastosowania badań rynku w celu wygenerowania spostrzeżeń odbiorców.)</li>' +
                            '<li>Pomiar wydajności treści <br/>' +
                            '(Aby dokonać pomiaru wydajności treści, dostawcy mogą: <br/>' +
                            '- zmierzyć i sporządzić raport na temat tego, czy treści były dostarczane użytkownikom i jaka była ich reakcja; <br/>' +
                            '- dostarczać raporty, używając bezpośrednio mierzalnych lub znanych informacji o użytkownikach, którzy zareagowali na treść. <br/>' +
                            'Dostawcy nie mogą: <br/>' +
                            '- zmierzyć, czy i w jaki sposób reklamy (w tym reklamy natywne) były dostarczane użytkownikowi i jaka była jego reakcja; <br/>' +
                            '- stosować danych pochodzących z analizy panelu lub podobnych odbiorców do danych pomiarowych z reklam bez odrębnej podstawy prawnej do zastosowania badań rynku w celu wygenerowania spostrzeżeń odbiorców.)</li>' +
                            '<li>Stosowanie badań rynkowych w celu generowania opinii odbiorców <br/>' +
                            '(Aby zastosować badania rynkowe w celu generowania opinii odbiorców, dostawcy mogą: <br/>' +
                            '- dostarczać reklamodawcom lub ich przedstawicielom zbiorcze raporty na temat odbiorców, do których dotarły ich reklamy, poprzez analizę panelową i podobną; <br/>' +
                            '- dostarczać zbiorcze raporty wydawcom na temat odbiorców, do których dotarły treści lub reklamy na ich terenie i którzy zareagowali na te treści lub reklamy, poprzez zastosowanie analizy panelowej i podobnej; <br/>' +
                            '- powiązać dane offline z użytkownikiem online na cele badań rynkowych w celu wygenerowania spostrzeżeń odbiorców, jeśli dostawcy zdecydowali się na dopasowanie i połączenie źródeł danych offline; <br/>' +
                            '- Połączyć tę informację z inną informacją zebraną wcześniej, w tym z różnych witryn i aplikacji. <br/>' +
                            'Dostawcy nie mogą: <br/>' +
                            '- mierzyć wydajności i skuteczności reklam, które wyświetlono konkretnemu użytkownikowi i na które on zareagował, bez odrębnej podstawy prawnej do pomiaru wydajności reklam; <br/>' +
                            '- mierzyć, jakie treści zaprezentowano konkretnemu użytkownikowi i jak na nie zareagował, bez odrębnej podstawy prawnej do pomiaru wydajności treści.)</li>' +
                            '<li>Opracowywanie i ulepszanie produktów <br/>' +
                            '( Aby opracowywać nowe produkty i ulepszać istniejące produkty, dostawcy mogą: <br/>' +
                            '- wykorzystywać informacje w celu uzupełniania istniejących produktów nowymi funkcjami i opracowywania nowych produktów; <br/>' +
                            '- tworzyć nowe modele i algorytmy poprzez uczenie maszynowe. <br/>' +
                            'Dostawcy nie mogą: <br/>' +
                            '- przeprowadzać w tym celu żadnych innych operacji przetwarzania danych dozwolonych w innym celu.)</li>' +
                            '</ul>' +
                            '<strong>Funkcje specjalne:</strong><br/>' +
                            '1. Użycie dokładnych danych geolokalizacyjnych <br/>' +
                            '(Dostawcy mogą: <br/>' +
                            '- gromadzić i obsługiwać dokładne dane geolokalizacyjne na potrzeby realizacji jednego albo większej liczby celów. Dokładna geolokalizacja oznacza, że nie ma ograniczeń w dokładności lokalizacji użytkownika; może być dokładna do kilku metrów.) <br/>' +
                            '2. Aktywne skanowanie charakterystyki urządzenia do celów identyfikacji <br/>' +
                            '(Dostawcy mogą: <br/>' +
                            '- stworzyć identyfikator przy użyciu danych zebranych poprzez aktywne skanowanie urządzenia w celu uzyskania określonych cech, np. zainstalowanych czcionek lub rozdzielczości ekranu; <br/>' +
                            '- użyć takiego identyfikatora, aby ponownie zidentyfikować urządzenie.) <br/>' +
                            '<br/>' +
                            'Zgodnie ze standardem wprowadzonym przez IAB Europe udostępniany mechanizm nie zawiera technicznej możliwości wyrażenia sprzeciwu wobec przetwarzania danych w najbardziej podstawowych celach obejmujących: (i) zapewnienie bezpieczeństwa technologicznego, zabezpieczenia przed oszustwami, usuwaniem szkodliwych programów, oraz (ii) techniczne umożliwienie interakcji użytkownika z prezentowaną treścią. Ściśle w celu realizacji zadań opisanych w pkt (i) powyżej i wyłącznie w niezbędnym zakresie mogą być przetwarzane dane geolokalizacyjne i dane będące efektem skanowania urządzenia dla celów identyfikacji. W odniesieniu do ww. sytuacji zawsze przeprowadzamy test równowagi pomiędzy naszym uzasadnionym prawnie interesem, a prawami osób, których dane dotyczą Jeżeli masz jakiekolwiek uwagi lub zastrzeżenia w powyższym zakresie lub chciałbyś indywidualnie złożyć sprzeciw, prosimy o kontakt pod adresem hello@partnerpszczelarza.pl<br/>' +
                            '<br/>' +
                            '<strong>Twoja zgoda na stosowanie Cookies:</strong><br/>' +
                            'W naszych aplikacjach używamy technologii, takich jak pliki cookie, local storage i podobnych służących do zbierania i przetwarzania danych osobowych oraz danych eksploatacyjnych w celu personalizowania udostępnianych reklam oraz analizowania ruchu na naszych stronach. Te pliki cookie pomagają poprawić jakość treści, w tym możliwych reklamowych na stronach i w aplikacjach. Dzięki tym technologiom możemy zapewnić Ci lepszą obsługę poprzez serwowanie treści lepiej dopasowanych do Twoich preferencji. Pliki te pozwalają nam również lepiej badać i analizować zainteresowanie naszych użytkowników, w tym satysfakcję z naszych usług. Dzięki tym analizom możemy zadbać o jakość usług i zadowolenie użytkowników. Szczegółową informację na temat plików cookie i ich funkcjonowania znajdziesz w poszczególnych zakładkach naszych aplikacji. Znajdziesz tam także informację o tym jak zmienić ustawienia przeglądarki / urządzenia, by ograniczyć lub wyłączyć funkcjonowanie plików cookies itp. oraz jak usunąć takie pliki z Twojego urządzenia. W razie jakichkolwiek wątpliwości zapraszamy do kontaktu na adres _hello@partnerpszczelarza.pl. Pomocne informacje znajdziesz także zawsze w naszej <a href="https://partnerpszczelarza.pl/polityka-prywatnosci" class="cc-link" target="_blank">Polityce Prywatności</a>.'
                    }, {
                        title: 'Niezbędne pliki cookies',
                        description: 'Niezbędne pliki cookie przyczyniają się do użyteczności strony poprzez umożliwianie podstawowych funkcji takich jak nawigacja w aplikacji i dostęp do bezpiecznej komunikacji. Aplikacja nie może funkcjonować poprawnie bez tych ciasteczek.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'konto_partner_pszczelarza_session',
                                col2: 'partnerpszczelarza.pl',
                                col3: 'w trakcie sesji',
                                col4: 'Wykorzystywane, aby identyfikować sesję użytkownika.',
                            },
                            {
                                col1: 'XSRF-TOKEN',
                                col2: 'partnerpszczelarza.pl',
                                col3: 'w trakcie sesji',
                                col4: 'Wykorzystywane, aby identyfikować sesję użytkownika.',
                            },
                            {
                                col1: 'cc_cookie',
                                col2: 'partnerpszczelarza.pl',
                                col3: '1 rok',
                                col4: 'Wykorzystywane, aby zapisać ustawienia cookies na urządzeniu.',
                            },
                        ]
                    }, {
                        title: 'Statystyczne pliki cookies',
                        description: 'Statystyczne pliki cookie pomagają nam zrozumieć, w jaki sposób różni użytkownicy zachowują się w apliacji, gromadząc i zgłaszając anonimowe informacje.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 lata',
                                col4: 'Wykorzystywane, aby przesłać do Google Analytics anonimowe dane o przeglądarce i zachowaniu użytkownika w celu analizy, optymalizacji i ulepszeń aplikacji.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Więcej informacji',
                        description: 'W razie jakichkolwiek wątpliwości zapraszamy do kontaktu na adres <a class="cc-link" href="mailto:hello@partnerpszczelarza.pl">hello@partnerpszczelarza.pl</a>. Pomocne informacje znajdziesz także zawsze w naszej <a href="https://partnerpszczelarza.pl/polityka-prywatnosci/" class="cc-link" target="_blank">Polityce Prywatności</a>.',
                    }
                ]
            }
        }
    }
});
